import InvoiceTypeLocalizer from "./InvoiceTypeLocalizer";

class InvoiceFileNameProvider {
  
  static getFileName(invoiceTypeId: string, invoiceNumber: number, invoiceNumberYear: number, extension: string) {
    const invoiceNumberFormat = InvoiceFileNameProvider.getFileNameFormat(invoiceTypeId, extension);
    const invoiceNumberText = invoiceNumberFormat.replace("{number}", invoiceNumber.toFixed(0)).replace("{year}", invoiceNumberYear.toFixed(0)).replace("{extension}", extension);
    return invoiceNumberText;
  }

  private static getFileNameFormat(invoiceTypeId: string, extension: string) {
    const invoiceType = InvoiceTypeLocalizer.getText(invoiceTypeId);
    if (!extension) {
      return `${invoiceType} št. {number}-{year}`;
    }
    
    if (extension === "zip") {
      return "{number}{year}.{extension}";
    }

    if (extension === "xml") {
      return 'eSlog_{number}{year}.{extension}';
    }

    if (extension === "ENV") {
      return 'Envelope_{number}{year}.{extension}';
    }
    
    return `${invoiceType} št. {number}-{year}.{extension}`;
  }

}

export default InvoiceFileNameProvider;
