import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import useEmailVerificationForm from 'hooks/useEmailVerificationForm';
import EmailAttachment from 'types/EmailAttachment';
import EmailModalAttachment from 'types/EmailModalAttachment';
import EmailModalForm from './EmailModalForm';

type EmailModalProps = {
  open: boolean;
  onSent: (messageIds: string[], recipient: string[]) => void;
  onClose: () => void;
  onEmailVerificationLinkSentSuccess: (message: string) => void;
  onEmailVerificationLinkSentError: (message: string) => void;
  sender: string;
  recipients: string[];
  subject: string;
  body: string;
  attachments: EmailModalAttachment[],
  getPdfAttachment: () => EmailAttachment;
  getZipAttachment: () => EmailAttachment;
  downloadPdfAttachment: () => void;
  downloadZipAttachment: () => void;
  accountingEmail?: string | undefined;
  invoiceId: string;
};

const EmailModal = (props: EmailModalProps) => {

  const emailVerificationForm = useEmailVerificationForm(props.onEmailVerificationLinkSentSuccess, props.onEmailVerificationLinkSentError, props.open);
  const [sending, setSending] = useState(false);

  const tryClose = () => {
    // Prevent closing the email modal dialog while the sending of the email is in progress.
    if (sending) {
      return;
    }

    props.onClose();
  };
  
  const content = emailVerificationForm.emailVerified ? (
    <EmailModalForm
    onSent={props.onSent}
    sender={props.sender}
    recipients={props.recipients}
    subject={props.subject}
    body={props.body}
    attachments={props.attachments}
    getPdfAttachment={props.getPdfAttachment}
    getZipAttachment={props.getZipAttachment}
    downloadPdfAttachment={props.downloadPdfAttachment}
    downloadZipAttachment={props.downloadZipAttachment}
    accountingEmail={props.accountingEmail}
    invoiceId={props.invoiceId}
    sending={sending}
    onSendingChanged={setSending}
    />
  ) : emailVerificationForm.render();

  return (
    <Dialog open={props.open} onClose={tryClose} className="modal-content">
      <div className="close-button" onClick={tryClose}>
        <CloseIcon />
      </div>
      <h1 className="title">Pošlji račun po e-pošti</h1>
      {props.open ? content : null}
    </Dialog>
  );
};

export default EmailModal;
